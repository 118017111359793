<template>
	<div>
		<CCard>
			<CCardBody>
				<div>
					<span
						:class="`${isTabInfor ? 'activeTab' : ''}`"
						type="button"
						class="btn btn-outline-dark b-d-0 m-w-135"
						@click="changeTabs(`${CUSTOMER_TABS.INFOR}`)"
					>
						{{ $t("CustomerTabs.Infor") }}
					</span>
					<span
						:class="`${isTabSchedule ? 'activeTab' : ''}`"
						type="button"
						class="btn btn-outline-dark b-d-0 m-w-135"
						@click="changeTabs(`${CUSTOMER_TABS.SCHEDULE}`)"
					>
						{{ $t("CustomerTabs.ExaminationSchedule") }}
					</span>
					<span
						:class="`${isTabMessage ? 'activeTab' : ''}`"
						type="button"
						class="btn btn-outline-dark b-d-0 m-w-135"
						@click="changeTabs(`${CUSTOMER_TABS.MESSAGE}`)"
					>
						{{ $t("CustomerTabs.MessageSent") }}
					</span>
					<span
						:class="`${isTabStatus ? 'activeTab' : ''}`"
						type="button"
						class="btn btn-outline-dark b-d-0 m-w-135"
						@click="changeTabs(`${CUSTOMER_TABS.STATUS}`)"
					>
						{{ $t("CustomerTabs.PrEpStatus") }}
					</span>
					<span
						:class="`${isTabRisk ? 'activeTab' : ''}`"
						type="button"
						class="btn btn-outline-dark b-d-0 m-w-135"
						@click="changeTabs(`${CUSTOMER_TABS.RISK}`)"
					>
						{{ $t("CustomerTabs.RiskAssessment") }}
					</span>
					<span
						:class="`${isTabGift ? 'activeTab' : ''}`"
						type="button"
						class="btn btn-outline-dark b-d-0 m-w-135"
						@click="changeTabs(`${CUSTOMER_TABS.GIFT}`)"
					>
						{{ $t("CustomerTabs.ProductBooking") }}
					</span>
				</div>
			</CCardBody>
		</CCard>

		<template v-if="isTabInfor">
			<CustomerForm
				:is-update="isUpdate"
				:pending-tab="pendingTab"
				@cancel="backPrevious"
				@submit="updateCustomerById"
				@delAccount="delAccount"
				@changeTab="changeTab"
				@cancel-change-tab="cancelChangeTab"
			/>
		</template>
		<template v-if="isTabSchedule">
			<ScheduleForm :id="id" @cancel="backPrevious" />
		</template>
		<template v-if="isTabMessage">
			<MessageList :id="id" @cancel="backPrevious" />
		</template>
		<template v-if="isTabStatus">
			<PrEPStatusForm :id="id" @cancel="backPrevious" />
		</template>
		<template v-if="isTabRisk">
			<RiskForm :id="id" @cancel="backPrevious" />
		</template>
		<template v-if="isTabGift">
			<ReceiveFreeGifts :id="id" @cancel="backPrevious" />
		</template>
	</div>
</template>

<script>
import {
	GET_CUSTOMER_DETAIL,
	UPDATE_CUSTOMER_BY_ID,
	DELETE_CUSTOMER_BY_ID,
} from "../store/action-types"
import { RESET_CUSTOMER_DETAIL } from "../store/mutation-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState, mapMutations } = createNamespacedHelpers("customers")
import { CUSTOMER_TABS } from "../../../shared/plugins/constants"

export default {
	name: "CustomerDetail",

	components: {
		CustomerForm: () => import("../components/CustomerForm"),
		ScheduleForm: () => import("../components/ScheduleForm"),
		MessageList: () => import("../components/MessageList"),
		PrEPStatusForm: () => import("../components/PrEPStatusForm"),
		RiskForm: () => import("../components/RiskForm"),
		ReceiveFreeGifts: () => import("../components/ReceiveFreeGifts"),
	},

	props: {
		id: {
			type: [Number, String],
			default: null,
		},
		isUpdate: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			CUSTOMER_TABS: CUSTOMER_TABS,
			activeTab: CUSTOMER_TABS.INFOR,
			pendingTab: CUSTOMER_TABS.INFOR,
		}
	},

	computed: {
		...mapState(["recentCustomer"]),

		isTabInfor: function () {
			return this.activeTab === CUSTOMER_TABS.INFOR
		},

		isTabSchedule: function () {
			return this.activeTab === CUSTOMER_TABS.SCHEDULE
		},

		isTabMessage: function () {
			return this.activeTab === CUSTOMER_TABS.MESSAGE
		},

		isTabStatus: function () {
			return this.activeTab === CUSTOMER_TABS.STATUS
		},

		isTabRisk: function () {
			return this.activeTab === CUSTOMER_TABS.RISK
		},

		isTabGift: function () {
			return this.activeTab === CUSTOMER_TABS.GIFT
		},
	},

	created() {
		this.getCustomerById()
	},

	beforeDestroy() {
		// this.RESET_CUSTOMER_DETAIL()
	},

	methods: {
		...mapActions({ GET_CUSTOMER_DETAIL, UPDATE_CUSTOMER_BY_ID, DELETE_CUSTOMER_BY_ID }),
		...mapMutations({ RESET_CUSTOMER_DETAIL }),

		getCustomerById: async function () {
			await this.GET_CUSTOMER_DETAIL(this.id)
		},

		goTo(pathName) {
			this.$router.push({ name: pathName })
		},

		updateCustomerById: async function (params) {
			await this.UPDATE_CUSTOMER_BY_ID({ params, id: this.id })
		},

		backPrevious() {
			this.$router.push({ name: "CustomerManagement" })
		},

		delAccount() {
			this.DELETE_CUSTOMER_BY_ID({ id: this.id })
		},

		changeTabs(tab) {
			this.pendingTab = tab
			if (this.activeTab === CUSTOMER_TABS.INFOR && this.activeTab !== tab) return
			this.activeTab = tab

			if (tab === CUSTOMER_TABS.INFOR) this.getCustomerById()
		},

		changeTab(newTab) {
			this.activeTab = newTab
		},

		cancelChangeTab() {
			this.pendingTab = CUSTOMER_TABS.INFOR
			this.activeTab = CUSTOMER_TABS.INFOR
		},
	},
}
</script>

<style lang="scss">
@import "@/assets/scss/variables";

.m-w-135 {
	min-width: 160px;
}

.activeTab {
	background-color: $sidebar-active-color;
	border-radius: 0.25rem;
	color: $base-color;
}
</style>
